<template>
    <b-card border-variant="primary" :header="fiscalia.nombre" header-bg-variant="primary" header-text-variant="white">
        <b-card-text>
            <b-row>
                <b-col cols="12 mt-3">
                    <div class="text-center">
                        <b-img :src="fiscalia.foto_fiscal" height="250" width="250" blank-color="#777" class="rounded mr-2 mb-1 mb-md-0" />
                        <b-card-title>{{ fiscalia.nombre_fiscal }}</b-card-title>
                    </div>
                </b-col>
                <b-col cols="12">
                    <h4><feather-icon icon="InfoIcon" size="18" /> <strong>Informacion:</strong></h4>

                    <p><strong>Dirección: </strong>{{ fiscalia.direccion }}</p>
                    <p><strong>Telefono: </strong>{{ fiscalia.telefono }}</p>
                    <p><strong>Fax: </strong>{{ fiscalia.fax }}</p>
                    <p><strong>Ubicacion: </strong>{{ fiscalia.ubicacion }}</p>
                </b-col>
                
            </b-row>
            <b-row>
                <b-col cols="12">
                <h4 class="mb-1"><feather-icon icon="MapPinIcon" size="18" /> <strong>Ubicacion Mapa:</strong></h4>
                <div>
                    <l-map :zoom.sync="zoom" :options="mapOptions" :center="center" style="height: 500px; width: 100%">
                        <l-tile-layer :url="url" :attribution="attribution" />
                        <l-marker :visible="ubicacion.visible" :draggable="ubicacion.draggable" :lat-lng.sync="ubicacion.position">
                            <l-popup :content="contenido" />
                            <l-tooltip :content="contenido"/>
                        </l-marker>
                    </l-map>
                </div>
            </b-col>
            </b-row>

            
        </b-card-text>
    </b-card>
</template>

<script>
import axios from '@axios';
import router from '@/router';

import { BImgLazy, BCardTitle, BFormCheckbox, BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { LMap, LTileLayer, LMarker, LPolyline, LLayerGroup, LTooltip, LPopup, LControlZoom, LControlAttribution, LControlScale, LControlLayers } from 'vue2-leaflet';
import { latLngBounds, Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),    
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    components: {
        BFormCheckbox,
        BCard,
        BCardTitle,
        BMedia,
        BAvatar,
        BCardText,
        BMediaAside,
        BMediaBody,
        BForm,
        BLink,
        BImg,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormFile,
        BImgLazy,
        vSelect,
        LMap,
        LTileLayer,
        LMarker,
        LPolyline,
        LLayerGroup,
        LTooltip,
        LPopup,
        LControlZoom,
        LControlAttribution,
        LControlScale,
        LControlLayers,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            contenido: '',

            fiscalia: {
                nombre_fiscal: '',
                nombre: '',
                direccion: '',
                telefono: '',
                fax: '',
                ubicacion: '',
            },
            fiscaliaFile: null,
            imagenMiniatura: '',
            fiscaliaOption: [],

            ubicacion: {
                position: { lat: -19.062117883514652, lng: -65.25878906250001 },
                visible: true,
                draggable: false,
            },
            selected: '0',
            zoom: 13,
            center: [-19.062117883514652, -65.25878906250001],
            mapOptions: {
                zoomControl: true,
                attributionControl: true,
                zoomSnap: true,
            },
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        };
    },
    created() {
        if (router.currentRoute.params.id) {
            axios
                .get(`/fiscalias/${router.currentRoute.params.id}`)
                .then((response) => {
                    this.fiscalia = response.data.data;
                    this.ubicacion.position.lat = this.fiscalia.latitud;
                    this.ubicacion.position.lng = this.fiscalia.longitud;
                    this.center = [this.fiscalia.latitud, this.fiscalia.longitud];
                    if (this.fiscalia.fiscalia_padre) {
                        this.fiscalia.fiscaliaDependiente = this.fiscalia.fiscalia_padre;
                        this.selected = '1';
                        this.getAllFiscalias();
                    }
                    this.contenido = `<div>
                   

                                        
                                        <table class="cuadro_info_dentro_mapa">
                                            <thead>
                                                <tr >
                                                    <th colspan="2" class="text-center">
                                                    <h5 class="text-black">${this.fiscalia.nombre}</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                           
                                            <tbody>                                            
                                            <tr>
                                                <th scope="row">Fiscal</th>
                                                <td>${this.fiscalia.nombre_fiscal}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Dirección</th>
                                                <td>${this.fiscalia.direccion}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Teléfono</th>
                                                <td>${this.fiscalia.telefono}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Fax</th>
                                                <td>${this.fiscalia.fax}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>`;
                })
                .catch((error) => console.error(error));
        }
    },
    setup() {
        const refInputEl = ref(null);
        const refPreviewEl = ref(null);

        return {
            refInputEl,
            refPreviewEl,
        };
    },

    methods: {},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

@import '@core/scss/vue/libs/map-leaflet.scss';
.custom-file-input:lang(es) ~ .custom-file-label::after {
    content: 'Buscar';
}

.vue2leaflet-map {
    &.leaflet-container {
        height: 350px;
    }
}

.cuadro_info_dentro_mapa{
    border-top: 2px solid black;
}
.cuadro_info_dentro_mapa thead tr{
    border-bottom: 2px solid black;
}

.cuadro_info_dentro_mapa tr{
    border-bottom: 1px solid black;

}
.cuadro_info_dentro_mapa tr td,th{
    text-transform: uppercase;
}

</style>

